import React from 'react'
import Layout from '../../../components/layouts'
import { Icon } from '@iconify/react';
import chevronRight from '@iconify/icons-mdi/chevron-right';
import { Location } from '@reach/router'
import Slider from 'react-slick';
import { instanceOf } from 'prop-types'
import Cookies from 'universal-cookie';
import Select from 'react-select';
import AsyncSelect from 'react-select/async'
import AsyncCreatableSelect from 'react-select/async-creatable'
import CreatableSelect from 'react-select/creatable'
import '../../../stylesheets/base/slick/slick-theme.scss'
import '../../../stylesheets/base/slick/slick.scss'
import IsIE from './IsIE'
import queryString from 'query-string'
import {Link} from 'gatsby'
import SEO from '../../../components/common/SEO';

// Query for the Blog Home content in Prismic

export default class Signup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentStep: 0,
            firstName: "",
            lastName: "",
            email: "",
            accountType: null,
            companyName: "",
            companyID: 0,
            companySize: 0,
            phone: "",
            positionName: "",
            positionID: 0,
            locationName: "",
            locationID: 0,
            industry: "",
            region: "",
            orientationID: 0,
            orientationList: [],
            isAdmin: false,
            isStepOneValid: false,
            isStepTwoValid : false,
            isStepThreeValid: false,
            firstNameError: "",
            lastNameError: "",
            emailError: "",
            companyNameError: "",
            companySizeError: "",
            phoneError: "",
            positionError: "",
            industryError: "",
            regionError: "",
            orientationIDError: "",
            companyList: [],
            companyNameSuggestionListComponent: (<div></div>),
            positionList: [],
            positionSuggestionListComponent: (<div></div>),
            locationList: [],
            signupError: (<div></div>),
            isLoading: false,
            companyNameOptions: [],
            positionNameOptions: [],
            orientationOptions: [],
            regionOptions: [],
            
        }
    }

    countrySelect = () => (
        <select name="industry" onBlur={this.handleRegionChange}>
        <option value="">País</option>
        <option value="Canada">Canada</option>
        <option value="United States">United States</option>
        <option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
        <option value="Afghanistan">Afghanistan</option>
        <option value="Aland Islands">Aland Islands</option>
        <option value="Albania">Albania</option>
        <option value="Algeria">Algeria</option>
        <option value="American Samoa">American Samoa</option>
        <option value="Andorra">Andorra</option>
        <option value="Angola">Angola</option>
        <option value="Anguilla">Anguilla</option>
        <option value="Antarctica">Antarctica</option>
        <option value="Antigua and Barbuda">Antigua and Barbuda</option>
        <option value="Argentina">Argentina</option>
        <option value="Armenia">Armenia</option>
        <option value="Aruba">Aruba</option>
        <option value="Australia">Australia</option>
        <option value="Austria">Austria</option>
        <option value="Azerbaijan">Azerbaijan</option>
        <option value="Bahamas">Bahamas</option>
        <option value="Bahrain">Bahrain</option>
        <option value="Bangladesh">Bangladesh</option>
        <option value="Barbados">Barbados</option>
        <option value="Belarus">Belarus</option>
        <option value="Belgium">Belgium</option>
        <option value="Belize">Belize</option>
        <option value="Benin">Benin</option>
        <option value="Bermuda">Bermuda</option>
        <option value="Bhutan">Bhutan</option>
        <option value="Bolivia">Bolivia</option>
        <option value="Bonaire, Sint Eustatius and Saba">Bonaire, Sint Eustatius and Saba</option>
        <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
        <option value="Botswana">Botswana</option>
        <option value="Bouvet Island">Bouvet Island</option>
        <option value="Brazil">Brazil</option>
        <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
        <option value="Brunei Darussalam">Brunei Darussalam</option>
        <option value="Bulgaria">Bulgaria</option>
        <option value="Burkina Faso">Burkina Faso</option>
        <option value="Burundi">Burundi</option>
        <option value="Cambodia">Cambodia</option>
        <option value="Cameroon">Cameroon</option>
        <option value="Cape Verde">Cape Verde</option>
        <option value="Cayman Islands">Cayman Islands</option>
        <option value="Central African Republic">Central African Republic</option>
        <option value="Chad">Chad</option>
        <option value="Chile">Chile</option>
        <option value="China">China</option>
        <option value="Christmas Island">Christmas Island</option>
        <option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
        <option value="Colombia">Colombia</option>
        <option value="Comoros">Comoros</option>
        <option value="Congo">Congo</option>
        <option value="Congo, Democratic Republic of the Congo">Congo, Democratic Republic of the Congo</option>
        <option value="Cook Islands">Cook Islands</option>
        <option value="Costa Rica">Costa Rica</option>
        <option value="Cote D'Ivoire">Cote D'Ivoire</option>
        <option value="Croatia">Croatia</option>
        <option value="Cuba">Cuba</option>
        <option value="Curacao">Curacao</option>
        <option value="Cyprus">Cyprus</option>
        <option value="Czech Republic">Czech Republic</option>
        <option value="Denmark">Denmark</option>
        <option value="Djibouti">Djibouti</option>
        <option value="Dominica">Dominica</option>
        <option value="Dominican Republic">Dominican Republic</option>
        <option value="Ecuador">Ecuador</option>
        <option value="Egypt">Egypt</option>
        <option value="El Salvador">El Salvador</option>
        <option value="Equatorial Guinea">Equatorial Guinea</option>
        <option value="Eritrea">Eritrea</option>
        <option value="Estonia">Estonia</option>
        <option value="Ethiopia">Ethiopia</option>
        <option value="Falkland Islands (Malvinas)">Falkland Islands (Malvinas)</option>
        <option value="Faroe Islands">Faroe Islands</option>
        <option value="Fiji">Fiji</option>
        <option value="Finland">Finland</option>
        <option value="France">France</option>
        <option value="French Guiana">French Guiana</option>
        <option value="French Polynesia">French Polynesia</option>
        <option value="French Southern Territories">French Southern Territories</option>
        <option value="Gabon">Gabon</option>
        <option value="Gambia">Gambia</option>
        <option value="Georgia">Georgia</option>
        <option value="Germany">Germany</option>
        <option value="Ghana">Ghana</option>
        <option value="Gibraltar">Gibraltar</option>
        <option value="Greece">Greece</option>
        <option value="Greenland">Greenland</option>
        <option value="Grenada">Grenada</option>
        <option value="Guadeloupe">Guadeloupe</option>
        <option value="Guam">Guam</option>
        <option value="Guatemala">Guatemala</option>
        <option value="Guernsey">Guernsey</option>
        <option value="Guinea">Guinea</option>
        <option value="Guinea-Bissau">Guinea-Bissau</option>
        <option value="Guyana">Guyana</option>
        <option value="Haiti">Haiti</option>
        <option value="Heard Island and Mcdonald Islands">Heard Island and Mcdonald Islands</option>
        <option value="Holy See (Vatican City State)">Holy See (Vatican City State)</option>
        <option value="Honduras">Honduras</option>
        <option value="Hong Kong">Hong Kong</option>
        <option value="Hungary">Hungary</option>
        <option value="Iceland">Iceland</option>
        <option value="India">India</option>
        <option value="Indonesia">Indonesia</option>
        <option value="Iran, Islamic Republic of">Iran, Islamic Republic of</option>
        <option value="Iraq">Iraq</option>
        <option value="Ireland">Ireland</option>
        <option value="Isle of Man">Isle of Man</option>
        <option value="IL">Israel</option>
        <option value="Israel">Italy</option>
        <option value="Jamaica">Jamaica</option>
        <option value="Japan">Japan</option>
        <option value="Jersey">Jersey</option>
        <option value="Jordan">Jordan</option>
        <option value="Kazakhstan">Kazakhstan</option>
        <option value="Kenya">Kenya</option>
        <option value="Kiribati">Kiribati</option>
        <option value="Korea, Democratic People's Republic of">Korea, Democratic People's Republic of</option>
        <option value="KR">Korea, Republic of</option>
        <option value="Korea, Republic of">Kosovo</option>
        <option value="Kuwait">Kuwait</option>
        <option value="Kyrgyzstan">Kyrgyzstan</option>
        <option value="Lao People's Democratic Republic">Lao People's Democratic Republic</option>
        <option value="Latvia">Latvia</option>
        <option value="Lebanon">Lebanon</option>
        <option value="Lesotho">Lesotho</option>
        <option value="Liberia">Liberia</option>
        <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
        <option value="Liechtenstein">Liechtenstein</option>
        <option value="Lithuania">Lithuania</option>
        <option value="Luxembourg">Luxembourg</option>
        <option value="Macao">Macao</option>
        <option value="Macedonia, the Former Yugoslav Republic of">Macedonia, the Former Yugoslav Republic of</option>
        <option value="Madagascar">Madagascar</option>
        <option value="Malawi">Malawi</option>
        <option value="Malaysia">Malaysia</option>
        <option value="Maldives">Maldives</option>
        <option value="Mali">Mali</option>
        <option value="Malta">Malta</option>
        <option value="Marshall Islands">Marshall Islands</option>
        <option value="Martinique">Martinique</option>
        <option value="Mauritania">Mauritania</option>
        <option value="Mauritius">Mauritius</option>
        <option value="Mayotte">Mayotte</option>
        <option value="Mexico">Mexico</option>
        <option value="Micronesia, Federated States of">Micronesia, Federated States of</option>
        <option value="Moldova, Republic of">Moldova, Republic of</option>
        <option value="Monaco">Monaco</option>
        <option value="Mongolia">Mongolia</option>
        <option value="Montenegro">Montenegro</option>
        <option value="Montserrat">Montserrat</option>
        <option value="Morocco">Morocco</option>
        <option value="Mozambique">Mozambique</option>
        <option value="Myanmar">Myanmar</option>
        <option value="Namibia">Namibia</option>
        <option value="Nauru">Nauru</option>
        <option value="Nepal">Nepal</option>
        <option value="Netherlands">Netherlands</option>
        <option value="Netherlands Antilles">Netherlands Antilles</option>
        <option value="New Caledonia">New Caledonia</option>
        <option value="New Zealand">New Zealand</option>
        <option value="Nicaragua">Nicaragua</option>
        <option value="Niger">Niger</option>
        <option value="Nigeria">Nigeria</option>
        <option value="Niue">Niue</option>
        <option value="Norfolk Island">Norfolk Island</option>
        <option value="Northern Mariana Islands">Northern Mariana Islands</option>
        <option value="Norway">Norway</option>
        <option value="Oman">Oman</option>
        <option value="Pakistan">Pakistan</option>
        <option value="Palau">Palau</option>
        <option value="Palestinian Territory, Occupied">Palestinian Territory, Occupied</option>
        <option value="Panama">Panama</option>
        <option value="Papua New Guinea">Papua New Guinea</option>
        <option value="Paraguay">Paraguay</option>
        <option value="Peru">Peru</option>
        <option value="Philippines">Philippines</option>
        <option value="Pitcairn">Pitcairn</option>
        <option value="Poland">Poland</option>
        <option value="Portugal">Portugal</option>
        <option value="Puerto Rico">Puerto Rico</option>
        <option value="Qatar">Qatar</option>
        <option value="Reunion">Reunion</option>
        <option value="Romania">Romania</option>
        <option value="Russian Federation">Russian Federation</option>
        <option value="Rwanda">Rwanda</option>
        <option value="Saint Barthelemy">Saint Barthelemy</option>
        <option value="Saint Helena">Saint Helena</option>
        <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
        <option value="Saint Lucia">Saint Lucia</option>
        <option value="Saint Martin">Saint Martin</option>
        <option value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</option>
        <option value="Saint Vincent and the Grenadines">Saint Vincent and the Grenadines</option>
        <option value="Samoa">Samoa</option>
        <option value="San Marino">San Marino</option>
        <option value="Sao Tome and Principe">Sao Tome and Principe</option>
        <option value="Saudi Arabia">Saudi Arabia</option>
        <option value="Senegal">Senegal</option>
        <option value="Serbia">Serbia</option>
        <option value="Serbia and Montenegro">Serbia and Montenegro</option>
        <option value="Seychelles">Seychelles</option>
        <option value="Sierra Leone">Sierra Leone</option>
        <option value="Singapore">Singapore</option>
        <option value="Sint Maarten">Sint Maarten</option>
        <option value="Slovakia">Slovakia</option>
        <option value="Slovenia">Slovenia</option>
        <option value="Solomon Islands">Solomon Islands</option>
        <option value="Somalia">Somalia</option>
        <option value="South Africa">South Africa</option>
        <option value="South Georgia and the South Sandwich Islands">South Georgia and the South Sandwich Islands</option>
        <option value="South Sudan">South Sudan</option>
        <option value="Spain">Spain</option>
        <option value="Sri Lanka">Sri Lanka</option>
        <option value="Sudan">Sudan</option>
        <option value="Suriname">Suriname</option>
        <option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option>
        <option value="Swaziland">Swaziland</option>
        <option value="Sweden">Sweden</option>
        <option value="Switzerland">Switzerland</option>
        <option value="Syrian Arab Republic">Syrian Arab Republic</option>
        <option value="Taiwan, Province of China">Taiwan, Province of China</option>
        <option value="Tajikistan">Tajikistan</option>
        <option value="Tanzania, United Republic of">Tanzania, United Republic of</option>
        <option value="Thailand">Thailand</option>
        <option value="Timor-Leste">Timor-Leste</option>
        <option value="Togo">Togo</option>
        <option value="Tokelau">Tokelau</option>
        <option value="Tonga">Tonga</option>
        <option value="Trinidad and Tobago">Trinidad and Tobago</option>
        <option value="Tunisia">Tunisia</option>
        <option value="Turkey">Turkey</option>
        <option value="Turkmenistan">Turkmenistan</option>
        <option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
        <option value="Tuvalu">Tuvalu</option>
        <option value="Uganda">Uganda</option>
        <option value="Ukraine">Ukraine</option>
        <option value="United Arab Emirates">United Arab Emirates</option>
        <option value="United Kingdom">United Kingdom</option>
        <option value="Uruguay">Uruguay</option>
        <option value="Uzbekistan">Uzbekistan</option>
        <option value="Vanuatu">Vanuatu</option>
        <option value="Venezuela">Venezuela</option>
        <option value="Viet Nam">Viet Nam</option>
        <option value="Virgin Islands, British">Virgin Islands, British</option>
        <option value="Virgin Islands, U.s.">Virgin Islands, U.s.</option>
        <option value="Wallis and Futuna">Wallis and Futuna</option>
        <option value="Western Sahara">Western Sahara</option>
        <option value="Yemen">Yemen</option>
        <option value="Zambia">Zambia</option>
        <option value="Zimbabwe">Zimbabwe</option>
</select>
    )
       

    componentDidMount() {
        // var _this = this;
        if (typeof window !== `undefined`) {
            this.populateRegionOptions();
            this.getPrimeContractorOrientations();      
        }
    
        const values = queryString.parse(window.location.search)

        // console.log(values.email)

        // console.log(this.state)

        // console.log(this.state.email)

       if (values.email) {
     
        this.setState({
            firstName: values.name,
            lastName: values.lname, 
            email: values.email,

         }, () => {
            console.log(this.state.email + this.state.firstName + this.state.lastName, 'it worked');
            this.handleNext()
          }); 
       }
      }

    getPrimeContractorOrientations() {
        window.fetch(`${process.env.GATSBY_API_URL}/workhub-signup/orientations`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(response => response.json())
        .then((response) => {
            let orientationOptions = [];
            response.forEach(orientation => {
                let customer = orientationOptions.filter(x => x.id === orientation.customerID)[0]
                if (customer) {
                    customer.options.push({
                        value: orientation.orientationID,
                        label: orientation.orientationName
                    })
                } else {
                    orientationOptions.push({
                        id: orientation.customerID,
                        label: orientation.customerName,
                        options: [{
                            value: orientation.orientationID,
                            label: orientation.orientationName
                        }]
                    })
                }
            });

            this.setState({orientationOptions});

        })
        .catch((error) => {
        });
    }


    slider;

    sliderNext = event => {
        this.setState({
            currentStep: this.state.currentStep + 1
        })
        this.slider.slickNext();
    }

    sliderTo = index => {
        this.setState({
            currentStep: index
        });
        this.slider.slickGoTo(index);
    }

    sliderSettings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        draggable: false,
        swipe: false,
        arrows: false,
        // adaptiveHeight: true,
    };

    handleCompanyChange = event => {
        if (event === null) {
            const companyName = ''
            let companyID = 0

            this.setState({companyName, companyID});
        } else {
            const companyName = event.label
            let companyID = 0
            if (!event.__isNew__) {
                companyID = event.value;
                this.populatePositionOptions(event.value);
                this.populateLocationList(event.value);
            } else {
                companyID = 0;
                this.populatePositionOptions(0);
                this.populateLocationList(0);
            }

            this.setState({companyName, companyID});
        }
    }

    handlePositionChange = event => {
        if (event === null) {
            const positionName = ''
            let positionID = 0

            this.setState({positionName, positionID});
        } else {
            const positionName = event.label
            let positionID = 0
            if (!event.__isNew__) {
                positionID = event.value
            }

            this.setState({positionName, positionID});
        }
    }

    handleRegionChange = event => {
        let regionName = '';
        let region = '';
        let locationName = '';
        if (event === null) {
            regionName = ''
            region = '';
            locationName = '';
        } else {
            regionName = event.target.value
            region = regionName;
            locationName = regionName;
        }

        // console.log(event.target.value)
       
        this.setState({region, locationName});

    }

    handleInputChange = event => {
        if(event != null) {
            // Orientation List
            if (event.length != null) {
                let orientationList = [];
                event.forEach(orientation => {
                    orientationList.push(orientation.value);
                });

                this.setState({orientationList});
            }
            // Company Name or Position Name change
            else if(event.value != null) {
                switch (event.type) {
                    case 0: // Company
                        const companyName = event.label
                        let companyID = 0
                        if (!event.__isNew__) {
                            companyID = event.value;
                            this.populatePositionOptions(event.value);
                        } else {
                            companyID = 0;
                            this.populatePositionOptions(0);
                        }
        
                        this.setState({companyName, companyID});
                        break;
                    case 1: // Position
                        const positionName = event.label
                        let positionID = 0
                        if (!event.__isNew__) {
                            positionID = event.value
                        }
        
                        this.setState({positionName, positionID});
                    break;
                    default:
                        break;
                }
            } 
            else {
                const target = event.target
                const value = target.value
                const name = target.name

                if (name === 'isAdmin') {
                    this.setState({
                        isAdmin: !this.state.isAdmin
                    })
                } else {
                    this.setState({
                        [name]: value
                    })
                }
            }
        }
    }

    /**
     * This function handles whether to show the create option or not
     * @param {*} event string which the user types on the company name field
     * @returns boolean based on whether to show the 'Create' option or not
     */
    showCreateOption = (event) => {
        if(event.toLocaleLowerCase() === 'workhub' 
        || event.toLocaleLowerCase() === 'work hub'
        || event.toLocaleLowerCase() === 'safetysync' 
        || event.toLocaleLowerCase() === 'safety sync' 
        || event === ''){
            return false;
        }
        return true;
    }

    handleCreateCompany = event => {
        this.handleInputChange({
            __isNew__: true,
            label: event,
            value: 0,
            type: 0
        })

    }
    
    handleCreatePosition = event => {
        this.handleInputChange({
            __isNew__: true,
            label: event,
            value: 0,
            type: 0
        })
        
    }
    populatePositionOptions(companyID) {
        let positionNameOptions = [];
        if (companyID > 0) {
            window.fetch(`${process.env.GATSBY_API_URL}/company/${companyID}/positionInfo/website`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then(response => {
                if (!response.ok) {
                    Promise.reject({err: response.status})
                } else {
                    return response.json()
                }
            })
            .then(response => {
                if (response.length > 0) {
                    let positionNameOptions = [];
                        response.forEach(position => {
                            positionNameOptions.push({
                                type: 1,
                                value: position.positionID,
                                label: position.positionName
                            })
                        });
                    this.setState({positionNameOptions})
                } else {
                    this.setState({positionNameOptions})
                }
            })
            .catch((error) => {
                this.setState({positionNameOptions})
            });
        }
        this.setState({positionNameOptions})
    }

    populateLocationList(companyID) {
        let locationList = [];
        if (companyID > 0) {
            window.fetch(`${process.env.GATSBY_API_URL}/company/${companyID}/locationInfo/website`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then(response => {
                if (!response.ok) {
                    Promise.reject({err: response.status})
                } else {
                    return response.json()
                }
            })
            .then(response => {
                if (response.length > 0) {
                    let locationList = [];
                        response.forEach(location => {
                            locationList.push({
                                value: location.locationID,
                                label: location.locationName
                            })
                        });
                    this.setState({locationList})
                } else {
                    this.setState({locationList})
                }
            })
            .catch((error) => {
                this.setState({locationList})
            });
        }
        this.setState({locationList})
    }

    populateRegionOptions() {

        let regionOptions = [];
        regionOptions.push({
            value: 1,
            label: 'United States',
        });
        regionOptions.push({
            value: 2,
            label: 'Canada',
        });
        regionOptions.push({
            value: 3,
            label: 'International',
        });
        this.setState({regionOptions})
    }

    selectType = accountType => {
        if(this.state.isStepOneValid) {
            setTimeout(() => {
                this.setState({accountType})
            });
            this.sliderNext();
        } else {
            this.sliderTo(0);
        }
    }

    handleStepChange = index => {
        switch(index) {
            case 0:
                this.sliderTo(index);
                break;
            case 1:
                if (this.state.isStepOneValid) {
                    this.sliderTo(index);
                }
                break;
            case 2:
                if (this.state.isStepOneValid && this.state.isStepTwoValid) {
                    this.sliderTo(index);
                }
                break;
            default:
                break;
        }
    }

    promiseOptions = inputValue => {
        if(inputValue.length > 3) {
            return window.fetch(`${process.env.GATSBY_API_URL}/customers/login-name`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    "LoginName": inputValue
                }),
            })
            .then(response => {
                if (!response.ok) {
                    // Promise.reject({err: response.status})
                    return null
                } else {
                    return response.json()
                }
            })
            .then(response => {
                if (response != null) {
                    if (response.length > 0) {
                        let companyNameOptions = [];
                            response.forEach(company => {
                                const blackList = company.id != 1
                                                && company.loginName.toLocaleLowerCase() != 'workhub'
                                                && company.loginName.toLocaleLowerCase() != 'work hub' 
                                                && company.loginName.toLocaleLowerCase() != 'safetysync'
                                                && company.loginName.toLocaleLowerCase() != 'safety sync'
                                if (blackList) {
                                    companyNameOptions.push({
                                        type: 0,
                                        value: company.id,
                                        label: company.loginName
                                    })
                                }
                            });
                        return companyNameOptions;
                    } else {
                        return [];
                    }
                }
                return [];
            })
            .catch((error) => {
                return [];
            });
        } else {
            return [];
        }
    }

    status(response) {   
        if (response.ok) {
            return response;
        }
        return response.json().then(res => Promise.reject(res));
    }

    // CompanyName autofill
    handleCompanyNameChange = event => {
        this.handleInputChange(event);
        const value = event.target.value;
        if (this.state.companyList.length > 0) {
            let companyNameSuggestionListComponent = (<div></div>)
            let companyList = this.state.companyList.filter(company => company.loginName.match(/value/));
            if (companyList.length > 0) {
                if(this.state.companyList.length > 0) {
                    companyNameSuggestionListComponent = (
                        <ul className="suggestions">
                            {this.state.companyList.map((suggestion, index) => {
                                return (
                                    <li
                                        key={suggestion.id}
                                        onMouseDown={() => {this.handleCompanyNameMouseDown(suggestion)}}
                                    >
                                        {suggestion.loginName}
                                    </li>
                                )
                            })}
                        </ul>
                    )
                }
                this.setState({companyList,companyNameSuggestionListComponent});
            } else {
                const isLoading = true;
                this.setState({isLoading});
                window.fetch(`${process.env.GATSBY_API_URL}/customers/login-name`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        "LoginName": value
                    }),
                })
                .then(response => response.json())
                .then((response) => {
                    let companyNameSuggestionListComponent = (<div></div>)
                    let companyList = response;
                    let isLoading = false;
                    if (response != undefined) {
                        if(response.length > 0) {
                            companyNameSuggestionListComponent = (
                                <ul className="suggestions">
                                    {response.map((suggestion, index) => {
                                        return (
                                            <li
                                                key={suggestion.id}
                                                onMouseDown={() => {this.handleCompanyNameMouseDown(suggestion)}}
                                            >
                                                {suggestion.loginName}
                                            </li>
                                        )
                                    })}
                                </ul>
                            )
                        }
                    }
                    this.setState({isLoading,companyList,companyNameSuggestionListComponent});
                })
                .catch((error) => {
                    let companyNameSuggestionListComponent = (<div></div>)
                    let companyList = [];
                    let isLoading = false;
                    this.setState({isLoading,companyList,companyNameSuggestionListComponent});
                });
            }
        } else {
            const isLoading = true;
            this.setState({isLoading});
            window.fetch(`${process.env.GATSBY_API_URL}/customers/login-name`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    "LoginName": value
                }),
            })
            .then(response => response.json())
            .then((response) => {
                let companyNameSuggestionListComponent = (<div></div>)
                let companyList = response;
                let isLoading = false;
                if (response != undefined) {
                    if(response.length > 0) {
                        companyNameSuggestionListComponent = (
                            <ul className="suggestions">
                                {response.map((suggestion, index) => {
                                    return (
                                        <li
                                            key={suggestion.id}
                                            onMouseDown={() => {this.handleCompanyNameMouseDown(suggestion)}}
                                        >
                                            {suggestion.loginName}
                                        </li>
                                    )
                                })}
                            </ul>
                        )
                    }
                }
                this.setState({isLoading,companyList,companyNameSuggestionListComponent});
            })
            .catch((error) => {
                let companyNameSuggestionListComponent = (<div></div>)
                let companyList = [];
                let isLoading = false;
                this.setState({isLoading,companyList,companyNameSuggestionListComponent});
            });
        }
    }

    handleCompanyNameFocus = event => {
        let companyNameSuggestionListComponent = (<div></div>)
        if(this.state.companyList != undefined) {
            if(this.state.companyList.length > 0) {
                companyNameSuggestionListComponent = (
                    <ul className="suggestions">
                        {this.state.companyList.map((suggestion, index) => {
                            // let className;
    
                            // Flag the active suggestion with a class
                            // if (index === )
                            return (
                                <li
                                    key={suggestion.id}
                                    onMouseDown={() => {this.handleCompanyNameMouseDown(suggestion)}}
                                >
                                    {suggestion.loginName}
                                </li>
                            )
                        })}
                    </ul>
                )
            }
        }
        this.setState({companyNameSuggestionListComponent});
    }

    handleCompanyNameMouseDown = suggestion => {
        let companyName = suggestion.loginName;
        let companyID = suggestion.id;
        let companyNameSuggestionListComponent = (<div></div>)
        this.setState( {
            companyName,
            companyID,
            companyNameSuggestionListComponent
        })
    }

    handleCompanyNameBlur = event => {
        let companyNameSuggestionListComponent = (<div></div>)
        this.setState({
            companyNameSuggestionListComponent
        })
    }

    handleNext = event => {
        const isStepOneValid = this.validateStepOne();
        if(isStepOneValid) {
            this.sliderNext();
        }
    }

    validateStepOne() {
        let isStepOneValid = false;
        let firstNameError = "";
        let lastNameError = "";
        let emailError = "";
        //  firstNameError: "",
        //  lastNameError: "",
        //  emailError: "",
        if (!this.state.firstName.length > 0) {
            firstNameError = "Nombre requerido"
        } else if (this.state.firstName.length > 25) {
            firstNameError = "El nombre debe contener menos de 25 caracteres"
        } else if (this.isValidHttpUrl(this.state.firstName) || this.state.firstName.indexOf(".") > -1) {
            firstNameError = "El nombre no puede ser un URL";
        }

        if (!this.state.lastName.length > 0) {
            lastNameError = "Apellido requerido"
        } else if (this.state.lastName.length > 25) {
            lastNameError = "El apellido debe contener menos de 25 caracteres"
        } else if (this.isValidHttpUrl(this.state.lastName) || this.state.lastName.indexOf(".") > -1) {
            lastNameError = "El apellido no puede ser un URL";
        }

        if (!this.isValidEmail(this.state.email)) {
            emailError = "Email invalido"
        } else if (this.state.email.length > 55) {
            emailError = "Email debe contener menos de 55 caracteres"
        }

        this.setState({firstNameError, lastNameError, emailError, isStepOneValid});
        if (firstNameError || lastNameError || emailError) {
           this.setState({firstNameError, lastNameError, emailError, isStepOneValid});
           return false;
        } else {
            isStepOneValid = true;
            this.setState({firstNameError, lastNameError, emailError, isStepOneValid});
            return true;
        }
    }

    isValidHttpUrl(string) {
        let url;
        
        try {
          url = new URL(string);
        } catch (_) {
          return false;  
        }
      
        return url.protocol === "http:" || url.protocol === "https:";
      }

    validateStepThree() {
        let isStepThreeValid = false;
        let isLoading = true;
        let companyNameError = "";
        let companySizeError = "";
        let phoneError = "";
        let positionError = "";
        let industryError = "";
        let regionError = "";
        // let orientationIDError = "";

        if(!this.state.companyName.length > 0) {
            companyNameError = "Nombre de empresa requerido"
        } else if (this.state.companyName.length > 40) {
            companyNameError = "El nombre de la empresa debe contener menos de 40 caracteres"
        }

        if(!this.state.phone.length > 0) {
            phoneError = "Número de teléfono requerido"
        } else if (this.state.phone.length > 20) {
            phoneError = "Número de teléfono debe contener menos de 20 caracteres"
        }

        switch (this.state.accountType) {
            case 0:
                //  regionError: "",
                if (!this.state.region.length > 0) {
                    regionError = "Por favor Seleccione una región"
                } else if (this.state.region.length > 50) {
                    regionError = "El nombre de la región debe contener menos de 50 caracteres"
                }

                if (!this.state.positionName.length > 0) {
                    positionError = "Nombre de cargo requerido"
                } else if (this.state.positionName.length > 50) {
                    positionError = "Nombre de cargo debe contener menos de 50 caracteres"
                }
                // UPDATE STATE
                if (companyNameError || phoneError || regionError || positionError) {
                    isLoading = false;
                    this.setState({companyNameError, phoneError, positionError, regionError, isStepThreeValid, isLoading});
                    return false;
                } else {
                    isStepThreeValid = true;
                    this.setState({companyNameError, phoneError, positionError, regionError, isStepThreeValid});
                    return true;
                }
                break;
            case 1:
                //  companySizeError: "",
                if (!this.state.companySize.length > 0) {
                    companySizeError = "Por favor seleccione el tamaño de su empresa"
                }
                //  positionError: "",
                if (!this.state.positionName.length > 0) {
                    positionError = "Nombre de cargo requerido"
                } else if (this.state.positionName.length > 50) {
                    positionError = "Nombre de cargo debe contener menos de 50 caracteres"
                }
                //  industryError: "",
                if (!this.state.industry.length > 0) {
                    industryError = "Por favor seleccione su industria"
                }
                //  regionError: "",
                if (!this.state.region.length > 0) {
                    regionError = "Por favor Seleccione una región"
                } else if (this.state.region.length > 50) {
                    regionError = "El nombre de la región debe contener menos de 50 caracteres"
                }

                // UPDATE STATE
                if (companyNameError || companySizeError || phoneError || positionError || industryError || regionError) {
                    isLoading = false;
                    this.setState({companyNameError, companySizeError, phoneError, positionError, industryError, regionError, isStepThreeValid, isLoading})
                    return false;
                } else {
                    isStepThreeValid = true;
                    this.setState({companyNameError, companySizeError, phoneError, positionError, industryError, regionError, isStepThreeValid})
                    return true;
                }
                break;
            case 2:
                if (!this.state.positionName.length > 0) {
                    positionError = "Nombre de cargo requerido"
                } else if (this.state.positionName.length > 50) {
                    positionError = "Nombre de cargo debe contener menos de 50 caracteres"
                }
                // UPDATE STATE
                if (companyNameError || phoneError || regionError || positionError) {
                    isLoading = false;
                    this.setState({companyNameError, phoneError, positionError, isStepThreeValid, isLoading});
                    return false;
                } else {
                    isStepThreeValid = true;
                    this.setState({companyNameError, phoneError, positionError, isStepThreeValid});
                    return true;
                }
                break;
            default:
                return false;
                break;
        }

    }

    handleSubmit = event => {
        event.preventDefault();

        this.setState({
            isLoading: true
        })

        const isStepThreeValid = this.validateStepThree();
        if(isStepThreeValid) {
            this.submitForm();
        }
    }

    getCookie(name) {
        var value = "; " + document.cookie;
        var parts = value.split("; " + name + "=");
        if (parts.length === 2) return parts.pop().split(";").shift();
    }

    

    submitForm() {
        let companyName;

        if (this.state.companyName.startsWith(`Crear "`)) {
            companyName = this.state.companyName.replace(`Crear "`, '');
            companyName = companyName.substring(0,companyName.length - 1);
        } else {
            companyName = this.state.companyName;
        }



        let positionName;

        if (this.state.positionName.startsWith(`Crear "`)) {
            positionName = this.state.positionName.replace(`Crear "`, '');
            positionName = positionName.substring(0,positionName.length - 1);
        } else {
            positionName = this.state.positionName;
        }



        let isLoading = true;
        if(process.env.GATSBY_API_URL.includes('https')) {
            const cookies = new Cookies();
            var obj = {};
            obj.fields = [];

            var emailObj = {};
            emailObj.name = 'email';
            emailObj.value = this.state.email;

            var fnameObj = {};
            fnameObj.name = 'firstname';
            fnameObj.value = this.state.firstName;

            var lnameObj = {};
            lnameObj.name = 'lastname';
            lnameObj.value = this.state.lastName;

            var jtitleObj = {};
            jtitleObj.name = 'jobtitle';

            jtitleObj.value = positionName;

            var phoneObj = {};
            phoneObj.name = 'phone';
            phoneObj.value = this.state.phone;

            var cnameObj = {};
            cnameObj.name = 'company';
            cnameObj.value = companyName;

            var numEmployeeString = '';
            switch (this.state.companySize) {
                case 5:
                    numEmployeeString = '1-5';
                    break;
                case 25:
                    numEmployeeString = '5-25';
                    break;
                case 50:
                    numEmployeeString = '25-50';
                    break;
                case 100:
                    numEmployeeString = '50-100';
                    break;
                case 500:
                    numEmployeeString = '100-500';
                    break;
                case 1000:
                    numEmployeeString = '500-1000';
                    break;
                case 1001:
                    numEmployeeString = '1000+';
                    break;

                default:
                    numEmployeeString = '0';
                    break;
            }
            var numEmployeesObj = {};
            numEmployeesObj.name = 'numemployees';
            numEmployeesObj.value = numEmployeeString;

            var industryObj = {};
            industryObj.name = 'industry';
            industryObj.value = this.state.industry;


            var accountTypeString = '';

            switch (this.state.accountType) {

                case 0:
                    accountTypeString = 'Training'
                    break;

                case 1:
                    accountTypeString = 'Free Trial'
                    break;

                case 2:
                    accountTypeString = 'Orientation'
                    break;

                default:

                    break;
            }
            var accountTypeObj = {};
            accountTypeObj.name = 'account_type'
            accountTypeObj.value = accountTypeString;

            
            var countryObj = {};
            countryObj.name = 'country';
            countryObj.value = this.state.region;


            obj.fields.push(emailObj);
            obj.fields.push(fnameObj);
            obj.fields.push(lnameObj);
            obj.fields.push(jtitleObj);
            obj.fields.push(phoneObj);
            obj.fields.push(cnameObj);
            obj.fields.push(numEmployeesObj);
            obj.fields.push(industryObj);
            obj.fields.push(accountTypeObj);
            obj.fields.push(countryObj);
            

            obj.context = {};
            // obj.context.hutk = this.getCookie('hubspotutk');
            obj.context.hutk = cookies.get('hubspotutk');
            obj.context.pageUri = 'www.workhub.com/signup';
            obj.context.pageName = 'Free Trial';
            
            window.fetch('https://api.hsforms.com/submissions/v3/integration/submit/2339207/663add41-4040-433d-974a-b644686cff73', {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json'
                },
                body: JSON.stringify(obj)
            });
        }

        const positionID = (this.state.positionNameOptions.filter(pos => pos.label === positionName)[0] != null) ? this.state.positionNameOptions.filter(pos => pos.label === positionName)[0].value : null
        const locationID = (this.state.locationList.filter(loc => loc.label === this.state.locationName)[0] != null) ? this.state.locationList.filter(loc => loc.label === this.state.locationName)[0].value : null

        // REMOVE Crear "" part of customer name if it exists
        if (companyName.startsWith("Crear \"")) {
            companyName = companyName.replace("Crear \"", '').slice(0,-1);
        }

        if (positionName.startsWith("Crear \"")) {
            positionName = positionName.replace("Crear \"", '').slice(0,-1);
        }
        switch(this.state.accountType) {
            // TRAINING COURSE
            case 0:
                window.fetch(`${process.env.GATSBY_API_URL}/workhub-signup`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        "SignupFirstName": this.state.firstName,
                        "SignupLastName": this.state.lastName,
                        "SignupEmail": this.state.email,
                        "SignupPhone": this.state.phone,
                        "SignupPosition": positionName,
                        "SignupPositionId": positionID,
                        "SignupLocation": this.state.locationName,
                        "SignupLocationId": locationID,
                        "SignupCompanyName": companyName,
                        // "SignupCustomerID": '0',
                        "SignupIsAfpa": false,
                        "SignupIsOrientation": false,
                        // "SignupRegion": this.state.region,
                        "SignupIsAdmin": true, // ALL FREE TRIAL SHOULD HAVE ADMIN PERMISSION
                        // "SignupCompanySize": this.state.companySize,
                        // "SignupIndustry": this.state.industry,
                        "SignupTemplateID": "27255"
                    }),
                })
                .then((response) => response.json())
                .then((data) => {
                    this.handleResult(data.url);
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
                break;
            // FREE TRIAL
            case 1:
                window.fetch(`${process.env.GATSBY_API_URL}/workhub-signup`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        "SignupFirstName": this.state.firstName,
                        "SignupLastName": this.state.lastName,
                        "SignupEmail": this.state.email,
                        "SignupPhone": this.state.phone,
                        "SignupPosition": positionName,
                        "SignupPositionId": positionID,
                        "SignupLocation": this.state.locationName,
                        "SignupLocationId": locationID,
                        "SignupCompanyName": companyName,
                        "SignupCustomerID": '0',
                        "SignupIsAfpa": false,
                        "SignupIsOrientation": null,
                        // "SignupRegion": this.state.region,
                        "SignupIsAdmin": true, // ALL FREE TRIAL SHOULD HAVE ADMIN PERMISSION
                        "SignupCompanySize": this.state.companySize,
                        "SignupIndustry": this.state.industry,
                        "SignupOrientationID": this.state.orientationID,
                    }),
                })
                .then((response) => response.json())
                .then((data) => {
                    this.handleResult(data.url);
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
                break;
            // ORIENTATION
            case 2:
                window.fetch(`${process.env.GATSBY_API_URL}/workhub-signup`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        "SignupFirstName": this.state.firstName,
                        "SignupLastName": this.state.lastName,
                        "SignupEmail": this.state.email,
                        "SignupPhone": this.state.phone,
                        "SignupPosition": positionName,
                        "SignupPositionId": positionID,
                        "SignupLocation": this.state.locationName,
                        "SignupLocationId": locationID,
                        "SignupCompanyName": companyName,
                        "SignupIsOrientation": true,
                        "SignupIsAdmin": true,
                        "SignupTemplateID": "6108",
                        "SignupOrientationList": this.state.orientationList
                      }),
                })
                .then((response) => response.json())
                .then((data) => {
                    this.handleResult(data.url);
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
            default:
                break;
        }
    }

    handleResult(url) {
        let isLoading = false;
        let signupError = (<span></span>)
        if (url != null) {
            if(url.includes("user-already-exists")) {
                signupError = (<span className="signup-message col-10"><span className="d-block">Woops! Looks like you already exist in this company, we've sent you a link to reset your password, if you think you know your password you can try <a href="https://app.workhub.com/" target="_blank" rel="noopener noreferrer">signing in here.</a></span></span>)
            } else if(url.includes("company-already-exists")) {
                signupError = (<span className="signup-message col-10"><span className="d-block">Woops! This company already has a Workhub account. Your request to be added has been received and is pending approval by their safety team. If you believe you already have a profile with this company and it's possibly under another email, you can try <a href="https://app.workhub.com/" target="_blank" rel="noopener noreferrer">logging in here.</a></span></span>)
            } else if(url.includes("forgot")) {
                window.location.href = url;
            } else if(url.includes("email-already-exists")) {
                this.handleStepChange(0);
                signupError = (<span className="signup-message col-10"><span className="d-block">Woops! A worker with the same email already exists in this company, Please use a different email or you can try <a href="https://app.workhub.com/" target="_blank" rel="noopener noreferrer">signing in here.</a></span></span>)
            }
        } else {
            signupError = (<span className="signup-message col-10"><span className="d-block">Woops! There was an issue processing your signup. Please contact: 1-888-668-6403</span></span>)
        }

        this.setState({signupError, isLoading})
    }
    
    buildStepThree = event => {

        const filterOption = ({label, value }, string) => {
            // default search
            if (label.toLocaleLowerCase().includes(string) || ('' + value).toLocaleLowerCase().includes(string)) {
                return true;
            }
            const groupOptions = this.state.orientationOptions.filter(customer => customer.label.toLocaleLowerCase().includes(string));

            if (groupOptions) {
                for (const groupOption of groupOptions) {
                    // Check if current option is in group
                    const option = groupOption.options.find(opt => opt.value === value);
                    if (option) {
                        return true;
                    }
                }
            }

            return false;
        }
        // ACCOUNT TYPES
        // 0 - TRAINING
        // 1 - FULL FEATURE
        // 2 - ORIENTATION
        switch (this.state.accountType) {
            case 0:
                return (
                    <>
                        <span className="stepper-container p-0 px-md-5">
                            <div className="training">
                                <span className="label">¡Genial! Ahora vamos a conseguirte el contenido apropiado.</span>
                                <span className="companyName">
                                    <AsyncCreatableSelect
                                        className="h-100"
                                        name="companyName"
                                        maxMenuHeight={300}
                                        onChange={this.handleCompanyChange}
                                        required
                                        placeholder="Nombre de tu Compañía"
                                        cacheOptions
                                        loadOptions={this.promiseOptions}
                                        defaultOptions 
                                        isValidNewOption={this.showCreateOption}
                                        />
                                        <span className="error">{this.state.companyNameError}</span>
                                </span>
                                <span className="phone">
                                    <input
                                        type="text"
                                        autoComplete="off"
                                        name="phone"
                                        placeholder="Número de Teléfono"
                                        value={this.state.phone}
                                        onChange={this.handleInputChange}
                                        required></input>
                                        <span className="error">{this.state.phoneError}</span>
                                </span>
                                <span className="position">
                                    {(this.state.companyID > 0) ? 
                                    (<CreatableSelect
                                        className="h-100"
                                        isClearable
                                        onChange={this.handlePositionChange}
                                        options={this.state.positionNameOptions}
                                        name="position"
                                        maxMenuHeight={200}
                                        required
                                        placeholder="Cargo"/>)
                                    :
                                    (<input 
                                        type="text"
                                        autoComplete="off"
                                        name="positionName"
                                        placeholder="Cargo"
                                        value={this.state.positionName}
                                        onChange={this.handleInputChange}
                                        required 
                                        maxLength="50"></input>)
                                    }
                                    <span className="error">{this.state.positionError}</span>
                                </span>
                                {/* <select name="region" onBlur={this.handleInputChange} className="region">
                                    <option value="">Select Region</option>
                                    <option value="United States">United States</option>
                                    <option value="Canada">Canada</option>    
                                </select> */}
                                <span className="region">
                                {/* <CreatableSelect
                                        className="h-100"
                                        isClearable
                                        onChange={this.handleRegionChange}
                                        options={this.state.regionOptions}
                                        name="region"
                                        maxMenuHeight={200}
                                        required
                                        placeholder="Region"/> */}
                                        <this.countrySelect/>
                                <span className="error">{this.state.regionError}</span>
                                </span>
                            </div>
                        </span>
                    </>
                )
            case 1:
                return (
                    <>
                        <span className="stepper-container p-0 px-md-5 my-3">
                            <div className="full">
                                <span className="label">¡Genial! Ahora vamos a personalizar tu experiencia.</span>
                                <span className="companyName">
                                    <AsyncCreatableSelect
                                        className="h-100"
                                        name="companyName"
                                        maxMenuHeight={300}
                                        onChange={this.handleCompanyChange}
                                        required
                                        placeholder="Nombre de tu Compañía"
                                        cacheOptions
                                        loadOptions={this.promiseOptions}
                                        defaultOptions 
                                        isValidNewOption={this.showCreateOption}
                                        />
                                    <span className="error">{this.state.companyNameError}</span>
                                </span>
                                <span className="phone">
                                    <input
                                        type="text"
                                        autoComplete="off"
                                        name="phone"
                                        placeholder="Número de Teléfono"
                                        value={this.state.phone}
                                        onChange={this.handleInputChange}
                                        required></input>
                                        <span className="error">{this.state.phoneError}</span>
                                </span>
                                <span className="position">
                                    {(this.state.companyID > 0) ? 
                                        (<CreatableSelect
                                            className="h-100"
                                            isClearable
                                            onChange={this.handlePositionChange}
                                            options={this.state.positionNameOptions}
                                            name="position"
                                            maxMenuHeight={200}
                                            required
                                            placeholder="Cargo"/>)
                                        :
                                        (<input 
                                            type="text"
                                            autoComplete="off"
                                            name="positionName"
                                            placeholder="Cargo"
                                            value={this.state.positionName}
                                            onChange={this.handleInputChange}
                                            required 
                                            maxLength="50"></input>)
                                        }
                                        <span className="error">{this.state.positionError}</span>
                                </span>
                                <span className="companySize">
                                    <select name="companySize" onBlur={this.handleInputChange}>
                                        <option value="">Tamaño de tu Compañía (50-100)*</option>
                                        <option value="5">1-5</option>
                                        <option value="25">5-25</option>
                                        <option value="50">25-50</option>
                                        <option value="100">50-100</option>
                                        <option value="500">100-500</option>
                                        <option value="1000">500-1000</option>
                                        <option value="1001">1000+</option>
                                    </select>
                                    <span className="error">{this.state.companySizeError}</span>
                                </span>
                                <span className="industry-region">
                                    <span className="industry p-0 pr-md-3 col-12 col-md-6 p-0">
                                        <select name="industry" onBlur={this.handleInputChange}>
                                            <option value="">Industria</option>
                                            <option value="Accommodation and Food Services">Accommodation and Food Services</option>
                                            <option value="Administrative and Support, Waste Management and Remediation Services">Administrative and Support, Waste Management and Remediation Services</option>
                                            <option value="Agriculture, Forestry, Fishing and Hunting">Agriculture, Forestry, Fishing and Hunting</option>
                                            <option value="Arts, Entertainment and Recreation">Arts, Entertainment and Recreation</option> 
                                            <option value="Construction">Construction</option>
                                            <option value="Educational Services">Educational Services</option> 
                                            <option value="Finance and Insurance">Finance and Insurance</option>
                                            <option value="Health Care and Social Assistance">Health Care and Social Assistance</option> 
                                            <option value="Information and Cultural Industries">Information and Cultural Industries</option>
                                            <option value="Management of Companies and Enterprises">Management of Companies and Enterprises</option> 
                                            <option value="Manufacturing">Manufacturing</option> 
                                            <option value="Mining, Quarrying, and Oil and Gas Extraction">Mining, Quarrying, and Oil and Gas Extraction</option>
                                            <option value="Other Services (except Public Administration)">Other Services (except Public Administration)</option> 
                                            <option value="Professional, Scientific and Technical Services">Professional, Scientific and Technical Services</option>
                                            <option value="Public Administration">Public Administration</option> 
                                            <option value="Real Estate and Rental and Leasing">Real Estate and Rental and Leasing</option>
                                            <option value="Retail Trade">Retail Trade</option> 
                                            <option value="Transportation and Warehousing">Transportation and Warehousing</option>
                                            <option value="Utilities">Utilities</option> 
                                            <option value="Wholesale Trade">Wholesale Trade</option>
                                        </select>
                                        <span className="error">{this.state.industryError}</span>
                                    </span>
                                    <span className="region col-12 col-md-6 p-0">
                                        {/* <select name="region" onBlur={this.handleInputChange}>
                                            <option value="">Region</option>
                                            <option value="United States">United States</option>
                                            <option value="Canada">Canada</option>    
                                        </select> */}

                                        {/* <CreatableSelect
                                        className="h-100"
                                        isClearable
                                        onChange={this.handleRegionChange}
                                        options={this.state.regionOptions}
                                        name="region"
                                        maxMenuHeight={200}
                                        required
                                        placeholder="Region"/> */}

                                        <this.countrySelect/>
                                        <span className="error">{this.state.regionError}</span>
                                    </span>
                                </span>
                            </div>
                        </span>
                    </>
                )
            case 2:
                return (
                    <>
                        <span className="stepper-container p-0 px-md-5 my-3">
                            <div className="orientation">
                                <span className="label">Great! Now let's find your orientation.</span>

                                <div className="d-flex flex-row flex-wrap mt-3">
                                    <span className="companyName d-flex flex-column col-12 p-0 mt-3">
                                        <AsyncCreatableSelect
                                            className="h-100"
                                            name="companyName"
                                            maxMenuHeight={300}
                                            onChange={this.handleCompanyChange}
                                            required
                                            placeholder="Nombre de tu Compañía"
                                            cacheOptions
                                            loadOptions={this.promiseOptions}
                                            defaultOptions 
                                            isValidNewOption={this.showCreateOption}
                                            />
                                            <span className="error">{this.state.companyNameError}</span>
                                    </span>
                                    
                                    <span className="companyName d-flex flex-column col-12 p-0 mt-3">
                                        <Select
                                            className="h-100"
                                            onChange={this.handleInputChange}
                                            filterOption={filterOption}
                                            placeholder="Select Orientations"
                                            closeMenuOnSelect={false}
                                            isMulti
                                            maxMenuHeight={250}
                                            options={this.state.orientationOptions}
                                        />
                                    </span>
                                    <span className="phone d-flex flex-column col-12 col-md-6 p-0 pr-md-3 mt-3">
                                        <input
                                            type="text"
                                            autoComplete="off"
                                            name="phone"
                                            placeholder="Número de Teléfono"
                                            value={this.state.phone}
                                            onChange={this.handleInputChange}
                                            required></input>
                                            <span className="error">{this.state.phoneError}</span>
                                    </span>
                                    <span className="position d-flex flex-column col-12 col-md-6 p-0 mt-3">
                                        {(this.state.companyID > 0) ? 
                                        (<CreatableSelect
                                            className="h-100"
                                            isClearable
                                            onChange={this.handlePositionChange}
                                            options={this.state.positionNameOptions}
                                            name="position"
                                            maxMenuHeight={200}
                                            required
                                            placeholder="Cargo"/>)
                                        :
                                        (<input 
                                            type="text"
                                            autoComplete="off"
                                            name="positionName"
                                            placeholder="Cargo"
                                            value={this.state.positionName}
                                            onChange={this.handleInputChange}
                                            required 
                                            maxLength="50"></input>)
                                        }
                                        <span className="error">{this.state.positionError}</span>
                                    </span>
                                </div>
                                {/* <span className="orientationName"><input type="text" name="orientationName" placeholder="Orientation I'm looking for (Company)" onChange={this.handleInputChange}></input></span> */}
                                {/* <span className="isAdmin">
                                    I am a:
                                    <label className="radio-container" htmlFor="worker">Worker
                                        <input id="worker" type="radio" checked={this.state.isAdmin === false} name="isAdmin" onChange={this.handleInputChange}/>
                                        <span className="checkmark"></span>
                                    </label>
                                    <label className="radio-container" htmlFor="admin">Administrator
                                        <input id="admin" type="radio" checked={this.state.isAdmin === true} name="isAdmin" onChange={this.handleInputChange}/>
                                        <span className="checkmark"></span>
                                    </label>
                                </span> */}
                            </div>
                        </span>
                    </>
                )
        
            default:
                break;
        }
    }

    buildActions = event => {
        switch(this.state.currentStep) {
            case 0:
                return (
                    <>
                        <button type="button" onClick={this.handleNext} onKeyDown={this.handleNext}>
                            <span className="buttonTxt">Siguiente</span>
                        </button>
                        <span className="login">¿Ya tienes una cuenta?  <a className="ml-2" href="https://app.workhub.com"> Inicia sesión</a></span>
                    </>
                )

            case 2:
                return(
                    <>
                        <button type="submit" disabled={this.state.isLoading} onClick={this.handleSubmit} onKeyDown={this.handleSubmit}>
                            <span className="buttonTxt">Siguiente</span>
                        </button>
                    </>
                )

            default:
                return(<></>)
        }     

    }

    isValidEmail(email) {
        var re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    };

    

    render() {
        const seo = {
            title:'Sign Up | Workhub',
            description: 'Signup to the workhub app today'
        }
        return(
            <Location>
                {locationProps => <Layout {...locationProps}>
                <SEO {...seo} />

                <div className="signup-box d-flex justify-content-center">
                    {/* LOGO */}
                    <div style={{maxWidth: '800px', padding: '1.5rem 1rem 0.75rem 1rem'}}>
                        <Link to='/es'>
                    <img style={{width: '100%', height: 'auto'}} src="/logo2x.png"></img></Link>
                    </div>

                    <IsIE/>
          
                    <div className="signup col-12 col-md-10 col-lg-8 col-xl-6 px-lg-1 mt-3">
                        <span className="title mt-5">Crea tu cuenta gratuita de Workhub</span>
                        <span className="subtitle mt-3">Regístrate en 50 segundos. No se requiere tarjeta de crédito</span>
                        <span className="stepper-header col-12 col-md-10 mt-5 align-self-center p-0">
                            <span role="button" tabIndex="-1" className={`col-4 step ${this.state.currentStep === 0 ? 'active' : ''}`}
                                onClick={() => { this.handleStepChange(0) }} onKeyDown={() => { this.handleStepChange(0) }}>
                                <span className="label">
                                    <Icon icon={chevronRight} />
                                    <span>Paso 1</span>
                                </span>
                                <span className="description">Dinos sobre ti</span>
                            </span>
                            <span role="button" tabIndex="-1" className={`col-4 step ${this.state.currentStep === 1 ? 'active' : ''}`}
                                onClick={() => { this.handleStepChange(1) }} onKeyDown={() => { this.handleStepChange(1) }}>
                                <span className="label">
                                    <Icon icon={chevronRight} />
                                    <span>Paso 2</span>
                                </span>
                                <span className="description">Elije tu tipo de cuenta</span>
                            </span>
                            <span role="button" tabIndex="-1" className={`col-4 step ${this.state.currentStep === 2 ? 'active' : ''}`}
                                onClick={() => { this.handleStepChange(2) }} onKeyDown={() => { this.handleStepChange(2) }}>
                                <span className="label">
                                    <Icon icon={chevronRight} />
                                    <span>Paso 3</span>
                                </span>
                                <span className="description">Personaliza tu experiencia</span>
                            </span>
                        </span>
                        <form className="p-0 px-md-5" onSubmit={this.handleSubmit}>
                            <Slider ref={r => (this.slider = r)} {...this.sliderSettings}>
                                {/* STEP 1 */}
                                <span className="stepper-container p-0 px-md-5 step-one my-3">
                                    <span className="label">Empecemos con lo básico</span>
                                    <div className="d-flex flex-row flex-wrap mt-3 align-self-center">
                                        <span className="first d-flex flex-column col-12 col-md-6 p-0 pr-md-3 mt-3">
                                            <input type="text" name="firstName" placeholder="Nombre" onChange={this.handleInputChange} required maxLength="25" value={this.state.firstName}></input>
                                            <span className="error">{this.state.firstNameError}</span>
                                        </span>
                                        <span className="last d-flex flex-column col-12 col-md-6 p-0 mt-3">
                                            <input type="text"  name="lastName" placeholder="Apellido" onChange={this.handleInputChange} required maxLength="25" value={this.state.lastName}></input>
                                            <span className="error">{this.state.lastNameError}</span>
                                        </span>
                                        <span className="email d-flex flex-column col-12 p-0 mt-3">
                                            <input type="text" name="email" placeholder="Email" onChange={this.handleInputChange} maxLength="55" value={this.state.email}></input>
                                            <span className="error">{this.state.emailError}</span>
                                        </span>
                                    </div>
                                </span>
                                {/* STEP 2 */}
                                <span className="stepper-container p-0 px-md-5 mb-3 my-3">
                                    <span className="label">Hola {this.state.firstName}. ¿Qué tipo de cuenta quisieras?</span>
                                    <span className="cards d-flex flex-row flex-wrap col-12">
                                        <span className="container d-flex col-12 col-lg-4 mt-3">
                                            <span className="card d-flex flex-column w-100" role="button" tabIndex="-1" onClick={() => this.selectType(0)} onKeyDown={() => this.selectType(0)}>
                                                <div className="es-tag"><span>Gratis</span></div>
                                                <span className="es-card-title">Cursos de Seguridad <br/> Únicamente</span>
                                                <span className="card-description">Estoy aquí por los cursos de seguridad</span>
                                                <span className="free"></span>
                                            </span>
                                        </span>
                                        <span className="container d-flex col-12 col-lg-4 mt-3">
                                            <span className="card d-flex flex-column w-100" role="button" tabIndex="-1" onClick={() => this.selectType(1)} onKeyDown={() => this.selectType(1)}>
                                                <div className="es-tag"><span>Demo</span></div>
                                                <span className="es-card-title">Experiencia Completa</span>
                                                <span className="card-description">Quisiera crear / evaluar un sistema de gestión de cumplimiento para mi organización</span>
                                                <span className="free"></span>
                                            </span>
                                        </span>
                                        <span className="container d-flex col-12 col-lg-4 mt-3">
                                            <span className="card d-flex flex-column w-100" role="button" tabIndex="-1" onClick={() => this.selectType(2)} onKeyDown={() => this.selectType(2)}>
                                                <div className="es-tag"><span>Gratis</span></div>
                                                <span className="es-card-title">Orientaciones</span>
                                                <span className="card-description">Estoy aquí por una orientación de un contratista</span>
                                                <span className="free"></span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                                {/* STEP 3 Training, Full, Orientation */}
                                {/* TRAINING */}
                                {this.buildStepThree()}
                            </Slider>
                            <span className="stepper-action">
                                {this.buildActions()}
                            </span>
                        </form>
                        {this.state.signupError}
                    </div>
                </div>
            </Layout>}
            </Location>
          )
    }
}
